@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');
body {
  overflow-x: hidden;
}
.poppins-thin {
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.poppins-extralight {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: normal;
}

.poppins-light {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.poppins-regular {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.poppins-medium {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.poppins-semibold {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.poppins-bold {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.poppins-extrabold {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-style: normal;
}

.poppins-black {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.poppins-thin-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.poppins-extralight-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: italic;
}

.poppins-light-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.poppins-regular-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.poppins-medium-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.poppins-semibold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: italic;
}

.poppins-bold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.poppins-extrabold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-style: italic;
}

.poppins-black-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-style: italic;
}

.dm-sans-normal {
  font-family: "DM Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight:500;
  font-style: normal;
}
.dm-sans-bold {
  font-family: "DM Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight:700;
  font-style: normal;
}
.faqstyling {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}
.mainFaq {
  box-shadow: 0px 7px 28px 0px rgba(153, 161, 170, 0.25);
  border-radius: 12px;
  width: 100%;
}
.parentfaq {
  border-radius: 24px;
  /* border: 2px solid #f5f5f5; */
width: 100%;
  /* background: #fff; */
  padding: 30px;
  backdrop-filter: blur(35px);
}

/* Hide the element on screen sizes 1024px and above */
@media (min-width: 1024px) {
  .d-lg-block {
    display: none !important;
  }
}
/* @media (min-width: 992px) {
    .d-lg-block {
        display: none;
    }
} */
/* color: var(--ethconnectt-eight-vercel-app-nero, #FFF); */

.navbutton {
  padding: 20px;
  background-color: #5956e9;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  color: white !important;
  border: none;
  margin-top: 10px;
  border-radius: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  letter-spacing: -0.64px;
}

.divborder {
  border-radius: 20px;
 
}


.image-container::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}


/* .image-container {
  display: flex;
  justify-content: center;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  position: relative;
}

.image-wrapper {
  flex: 0 0 auto;
  margin: 0 20px;
}

.scroll-wrapper {
  display: flex;
  animation: scroll-left 6s linear infinite;
}

@keyframes scroll-left {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-20%);
  }
} */



.scroller {
  max-width: 600px;
  border:10px solid red;
}

.scroller__inner {
  padding-block: 1rem;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.scroller[data-animated="true"] {
  overflow: hidden;
  -webkit-mask: linear-gradient(
    90deg,
    transparent,
    white 20%,
    white 80%,
    transparent
  );
  mask: linear-gradient(90deg, transparent, white 20%, white 80%, transparent);
}

.scroller[data-animated="true"] .scroller__inner {
  width: max-content;
  flex-wrap: nowrap;
  animation: scroll var(--_animation-duration, 40s)
    var(--_animation-direction, forwards) linear infinite;
}

.scroller[data-direction="right"] {
  --_animation-direction: reverse;
}

.scroller[data-direction="left"] {
  --_animation-direction: forwards;
}

.scroller[data-speed="fast"] {
  --_animation-duration: 20s;
}

.scroller[data-speed="slow"] {
  --_animation-duration: 60s;
}

@keyframes scroll {
  to {
    transform: translate(calc(-50% - 0.5rem));
  }
}

/* general styles */

:root {
  --clr-neutral-100: hsl(0, 0%, 100%);
  --clr-primary-100: hsl(205, 15%, 58%);
  --clr-primary-400: hsl(215, 25%, 27%);
  --clr-primary-800: hsl(217, 33%, 17%);
  --clr-primary-900: hsl(218, 33%, 9%);
}

/* html {
  color-scheme: dark;
} */

/* body {
  display: grid;
  min-block-size: 100vh;
  place-content: center;
  font-family: system-ui;
  font-size: 1.125rem;
  background-color: var(--clr-primary-800);
} */

.tag-list {
  margin: 0;
  padding-inline: 0;
  list-style: none;
}

.tag-list li {
  padding: 1rem;
  background: var(--clr-primary-400);
  border-radius: 0.5rem;
  box-shadow: 0 0.5rem 1rem -0.25rem var(--clr-primary-900);
}

/* for testing purposes to ensure the animation lines up correctly */
.test {
  background: red !important;
}
/* Add this CSS to your stylesheet */
/* Add this CSS to your stylesheet */
/* Adjust styles for the image container and marquee animation */
.image-container {
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  position: relative;
}

.marquee {
  display: flex;
  animation: marquee 15s linear infinite;
}

@keyframes marquee {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}

/* Ensure image wrappers are inline blocks and non-shrinking */
.image-wrapper {
  display: inline-block;
  margin: 0 180px;
  flex-shrink: 0;
}

/* Adjust brand grid size for responsiveness */
.brand-grid {
  width: 120px;
}

.brand-image {
  width: 100%;
  height: auto;
}

/* Responsive adjustments */
@media (min-width: 640px) {
  .brand-grid {
    width: 120px;
    /* border:5px solid red; */
  }
  .image-wrapper {
    display: inline-block;
    margin: 0 150px;
    flex-shrink: 0;
  }
}

@media (min-width: 1024px) {
  .brand-grid {
    width: 190px;
  }
  .image-wrapper {
    display: inline-block;
    margin: 0 32px;
    flex-shrink: 0;
  }
}

@media (min-width: 1440px) {
  .brand-grid {
    width: 120px;
  }
  .image-wrapper {
    display: inline-block;
    margin: 0 90px;
    flex-shrink: 0;
  }
}
.playwrite-normal {
  font-family: "Playwrite MX", cursive;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}
/* Add this CSS to your stylesheet */


/* Show the container on smaller screens */
/* Add this CSS to your stylesheet */
/* Add this CSS to your stylesheet */
/* Add this CSS to your stylesheet */
.image-container-mobile {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  justify-items: center;
}

.image-wrappermobile {
  width: 100%;
}

.brand-gridmobile {
  width: 100%;
}

@media (min-width: 1025px) {
  .image-container-mobile {
    display: none;
  }
}

@media (max-width: 1024px) {
  .image-container-mobile {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    justify-items: center;
  }

}



.icon{
  transition: transform 0.3s ease-in-out;
}

.icon:hover,
.icon:focus {
  transform: scale(1.2);
}

.icon:active {
  transform: scale(1.3);
}

/* Add these styles to your CSS file */

/* Add these styles to your CSS file */

/* Add these styles to your CSS file */
/* Add these styles to your CSS file */

/* Add these styles to your CSS file */

/* Add these styles to your CSS file */

/* Add these styles to your CSS file */


.slider-container {
  width: 100%;
  /* height: 100vh; */
}
.boxshadow{
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 20px;
}

.slider {
  --slide-duration:500ms;
  width: 100%;
  height: 33rem;
  position: relative;

  & > .controls {
    width: 100%;
    /* height: 100%; */
    position: absolute;
    display: flex;
    justify-content: space-between;
    z-index: 1;

    & > .control {
      width: 100px;
      cursor: pointer;
      padding: .5rem;
      display: flex;
      align-items: center;
      text-shadow: -1px 0px 5px rgba(0, 0, 0, 1);
      font-size: 3rem;

      &.previous {
        justify-content: flex-start;
      }

      &.next {
        justify-content: flex-end;
      }
    }
  }

  & > .slides {
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;

    & > .slide {
      width: 100%;
      height: 100%;
      position: absolute;
      opacity: 0;

      &[data-previous-slide="true"] {
        opacity: 1;
        transform:translateX(-100%);
      }

      &[data-current-slide="true"] {
        opacity: 1;
        transform:translateX(0);
      }

      &[data-next-slide="true"] {
        opacity: 1;
        transform:translateX(100%);
      }
      
      &.center,
      &.left,
      &.right {
        transition: all var(--slide-duration);
        opacity: 1;
      }

      &.center {
        transform: translateX(0);
      }

      &.left {
        transform: translateX(-100%);
      }

      &.right {
        transform: translateX(100%);
      }

     

      & > .text-container {
        /* background:#fff9; */
        position: absolute;
        padding: 1rem;
        max-width:50%;
        bottom: 5rem;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        border-radius: 4px;
      }
    }
  }

  & > .slide-pills {
    z-index: 2;
    position: absolute;
    bottom: 1rem;
    display: flex;
    gap: 1em;
    left: 50%;
    transform: translateX(-50%);

    & > .slide-pill {
      cursor: pointer;
        font-size: 0.7em;
        padding: 0.2rem 1rem;
        /* background: #000; */
        color: #fff;
        border-radius: 4px;
        transition: all var(--slide-duration);
        pointer-events: none;

        &.active {
          background: #fff;
          color: #000
        }
    }
  }
}
